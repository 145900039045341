import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarSolicitacao = async (page, payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_SOLICITACAO + `?page=${page}`, payload);
    return data
}

export const dadosSolicitacao = async (id) => {
    const { data } = await apiInstance.post(ENDPOINTS.DADOS_SOLICITACAO + `/${id}`);
    return data
}

export const excluirSolicitacao = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_SOLICITACAO + `/${id}`);
    return data
}

export const editarSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_SOLICITACAO + `/${id}`, payload);
    return data
}

export const criarSolicitacao = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_SOLICITACAO, payload);
    return data
}

export const exibirSolicitacao = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_SOLICITACAO + `/${id}`);
    return data
}

export const reenviarRespotaSolicitacao = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.REENVIAR_RESPOSTA_SOLICITACAO + `/${id}`);
    return data
}

export const inserirComentarioSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.INSERIR_COMENTARIO_SOLICITACAO + `/${id}`, payload);
    return data
}

export const excluirComentarioSolicitacao = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_COMENTARIO_SOLICITACAO + `/${id}`);
    return data
}

export const iniciarAtendimentoSolicitacao = async (id) => {
    const { data } = await apiInstance.put(ENDPOINTS.INICIAR_ATENDIMENTO_SOLICITACAO + `/${id}`);
    return data
}

export const encaminharSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.ENCAMINHAR_SOLICITACAO + `/${id}`, payload);
    return data
}

export const finalizarSemRespostaSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.FINAZLIZAR_SEM_RESPOSTA_SOLICITACAO + `/${id}`, payload);
    return data
}

export const deixarAtentimentoSolicitacao = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.DEIXAR_ATENDIMENTO_SOLICITACAO + `/${id}`);
    return data
}

export const consultaInternaSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.CONSULTA_INTERNA_SOLICITACAO + `/${id}`, payload);
    return data
}

export const encaminharAprovacaSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.ENCAMINHAR_APROVACAO_SOLICITACAO + `/${id}`, payload);
    return data
}

export const dadosPostagemSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.DADOS_POSTAGEM_SOLICITACAO + `/${id}`, payload);
    return data
}

export const finalizarEnviarSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.FINALIZAR_ENVIAR_SOLICITACAO + `/${id}`, payload);
    return data
}

export const responderAprovacaoSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.RESPONDER_APROVACAO_SOLICITACAO + `/${id}`, payload);
    return data
}

export const criarTreinamentoSolicitacao = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_PARA_TREINAMENTOS_SOLICITACAO, payload);
    return data
}

export const editarTreinamentoSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_PARA_TREINAMENTOS_SOLICITACAO + `/${id}`, payload);
    return data
}

export const atualizarModeloSolicitacao = async (id, payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.ATUALIZAR_MODELO_SOLICITACAO + `/${id}`, payload);
    return data;
  };